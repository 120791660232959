









































import ProductAddPopup from '@/components/ui/popups/takeAway/ProductAddPopup/index.vue';
import ProductWithIngredientsAddPopup from '@/components/ui/popups/takeAway/ProductWithIngredientsAddPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import BuyXGetYProduct from '@/models/buyXGetYProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State, Getter} from 'vuex-class';

@Component({
  name: 'NecessaryProductsPanelProduct',
  directives: {
    onClickaway: onClickaway,
  },
})
export default class NecessaryProductsPanelProduct extends Vue {
  @Prop({
    type: BuyXGetYProduct,
    required: true,
  })
  private product!: BuyXGetYProduct;

  private popupController: PopupControllerPublicProperties = getPopupController();

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @Getter('getProductByProjectProductId', {
    namespace: 'takeAway/menu',
  })
  private getProductByProjectProductId!: (projectProductId: string) => Product | null;

  private get image() {
    if (this.product.image) {
      return this.$generateAssetsUrl(this.product.image);
    }

    return '../../../../../../assets/images/product-placeholder.png';
  }

  private selectProduct() {
    const product = this.getProductByProjectProductId(this.product.id);

    if (product === null) {
      return;
    }

    this.$emit('openProduct');

    if (product.isHaveIngredientsForSelect) {
      this.openAddProductWithIngredientsPopup(product);

      return;
    }

    this.openAddProductPopup(product);
  }

  private openAddProductWithIngredientsPopup(product: Product) {
    this.popupController.createPopup(ProductWithIngredientsAddPopup, {
      propsData: {
        product: product,
      },
    });
  }

  private openAddProductPopup(product: Product) {
    this.popupController.createPopup(ProductAddPopup, {
      propsData: {
        product: product,
      },
    });
  }
}
