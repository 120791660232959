









































import PromotionProductAddPopup from '@/components/ui/popups/takeAway/PromotionProductAddPopup/index.vue';
import PromotionProductWithIngredientsAddPopup
  from '@/components/ui/popups/takeAway/PromotionProductWithIngredientsAddPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import {PopupControllerPublicProperties} from '@/lib/vuePopup/interfaces';
import BuyXGetY from '@/models/buyXGetY';
import BuyXGetYProduct from '@/models/buyXGetYProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import { directive as onClickaway } from 'vue-clickaway';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State, Getter} from 'vuex-class';

@Component({
  name: 'ChooseProductPanelProduct',
  directives: {
    onClickaway: onClickaway,
  },
})
export default class ChooseProductPanelProduct extends Vue {
  @Prop({
    type: BuyXGetY,
    required: true,
  })
  private promotion!: BuyXGetY;

  @Prop({
    type: BuyXGetYProduct,
    required: true,
  })
  private product!: BuyXGetYProduct;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get productsCountToReceive(): number {
    const productsCountToReceive = this.selectedPlace.settings.widget.maxProductCount - this.order.productsCount;

    return Math.min(productsCountToReceive, this.countOfReceivedProducts);
  }

  private get countOfReceivedProducts() {
    const addedCount = this.order.buyXGetY[this.promotion.id] ? this.order.buyXGetY[this.promotion.id].count : 0;

    return this.promotion.clientProgressReceivedCount - addedCount;
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @Getter('getProductByProjectProductId', {
    namespace: 'takeAway/menu',
  })
  private getProductByProjectProductId!: (projectProductId: string) => Product | null;

  private get image() {
    if (this.product.image) {
      return this.$generateAssetsUrl(this.product.image);
    }

    return '../../../../../../assets/images/product-placeholder.png';
  }

  private selectProduct() {
    const product = this.getProductByProjectProductId(this.product.id);

    if (product === null) {
      return;
    }

    this.$emit('openProduct');

    if (product.isHaveIngredientsForSelect) {
      this.openAddProductWithIngredientsPopup(product);

      return;
    }

    this.openAddProductPopup(product);
  }

  private openAddProductWithIngredientsPopup(product: Product) {
    this.popupController.createPopup(PromotionProductWithIngredientsAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
      listeners: [
        {
          event: 'close',
          callback: this.$listeners.close,
        }
      ],
    });
  }

  private openAddProductPopup(product: Product) {
    this.popupController.createPopup(PromotionProductAddPopup, {
      propsData: {
        product,
        promotion: this.promotion,
        maxCount: this.productsCountToReceive,
      },
      listeners: [
        {
          event: 'close',
          callback: this.$listeners.close,
        }
      ],
    });
  }
}
