















































































































































































































































































































































































































































import takeAwayService from '@/api/services/takeAway.service';
import CreditCard from '@/components/ui/creditCard/CreditCard.vue';
import OrderCookedTime from '@/components/ui/popups/takeAway/OrderClientAddPopup/components/OrderCookedTime.vue';
import OrderFailedConfirmedPopup from '@/components/ui/popups/takeAway/OrderFailedConfirmedPopup/index.vue';
import OrderSuccessConfirmedPopup from '@/components/ui/popups/takeAway/OrderSuccessConfirmedPopup/index.vue';
import HiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import CreditCardModel from '@/models/creditCard/creditCard';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import string from '@/utils/format/string';
import OrderTimeUnit from './components/OrderTimeUnit.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import AddCardPopup from '@/components/ui/popups/takeAway/AddCardPopup/index.vue';
import Place from '@/models/place';
import TakeAway from '@/models/takeAway';
import orderValidationModel from '@/validators/models/order';
import getPopupController from '@/lib/vuePopup';
import cloneDeep from 'lodash.clonedeep';
import { AsYouType } from 'libphonenumber-js';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State, Getter, Mutation } from 'vuex-class';
import { ActionMethod, MutationMethod, MutationPayload } from 'vuex';
import { sameAs, required, minLength, maxLength, requiredIf, numeric } from 'vuelidate/lib/validators';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { REMOVE_ORDER_MUTATION_TYPE } from '@/store/takeAway/order';
import { TAKE_AWAY_TIME_SHIFT } from '@/consts';
import { OrderType } from '@/interfaces/models/order';
import StreetTypeAhead from '@/components/ui/popups/takeAway/OrderClientAddPopup/components/StreetTypeAhead.vue';
import ConfirmRemoveCardPopup from "@/components/ui/popups/core/ConfirmRemoveCardPopup/index.vue";

@Component({
  name: 'OrderClientAddPopup',
  components: {
    OrderCookedTime,
    CreditCard,
    StreetTypeAhead,
    OrderTimeUnit,
    PopupWrapper
  },
  validations(): any {
    const self = <any> this;

    if (self.user) {
      return {
        editableOrder: {
          street: {
            requiredIf: requiredIf('isDelivery'),
          },
          entrance: {
            numeric,
          },
          floor: {
            numeric,
          },
        },
        isAgreeWithTermOfUseOfTheService: {
          sameAs: sameAs(
            () => true
          )
        },
      };
    }

    return {
      editableOrder: orderValidationModel,
      isAgreeWithTermOfUseOfTheService: {
        sameAs: sameAs(
          () => true
        )
      },
      code: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5)
      },
    };
  }
})
export default class OrderClientAddPopup extends Vue {
  private editableOrder: TakeAway = new TakeAway();
  private code: string = '';
  private isAgreeWithTermOfUseOfTheService: boolean = false;
  private popupController: PopupControllerPublicProperties = getPopupController();
  private timeShifts = TAKE_AWAY_TIME_SHIFT;
  private ORDER_TYPES = OrderType;
  private newCreditCards: CreditCardModel[] = [];
  private selectedCreditCardId: string | null = null;
  private phoneConfirmationSent: boolean = false;
  private phoneConfirmationValid: boolean = false;
  private time: number = 0;
  private errors: any = {
    phoneConfirmation: [],
    code: null,
  };
  private createdOrder: any | null = null;
  private redirectUrl: string | null = null;
  private payOnline: boolean = true;

  @State('order', {
    namespace: 'takeAway/order'
  })
  private readonly order!: TakeAway;

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  private get allCreditCards(): HiddenCreditCard[] {
    return [
      ...this.newCreditCards.map(HiddenCreditCardFactory.createFromCreditCard),
      ...this.creditCards,
    ];
  }

  private get resendTime(): string {
    if (0 === this.time) {
      return 'Отправить повторно';
    }

    const minutes = Math.floor(this.time / 60);
    const seconds = this.time % 60;


    return `${string.t2(minutes)}:${string.t2(seconds)}`;
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  @State('user', {
    namespace: 'user'
  })
  private user!: null | any;

  @State('creditCards', {
    namespace: 'user'
  })
  private creditCards!: HiddenCreditCard[];

  @State('utcNowInSeconds', {
    namespace: 'timer'
  })
  private utcNowInSeconds!: number;

  @Watch('utcNowInSeconds')
  private onUtcNowInSecondsChange() {
    if (this.time > 0) {
      this.time--;
    }
  }

  @Watch('editableOrder.client.phone')
  private onStaffPhoneChanged(phone: string) {
    const formattedPhone = new AsYouType().input(phone);

    if (formattedPhone !== phone) {
      this.editableOrder.client.phone = formattedPhone;
    }
  }

  @Watch('isPlaceOpen')
  private onIsPlaceOpenChange(isPlaceOpen: number) {
    if (!isPlaceOpen) {
      this.close();
    }
  }

  @Watch('creditCards')
  private onCreditCardsChange(creditCards: HiddenCreditCard[]) {
    if (null === this.selectedCreditCardId) {
      this.selectedCreditCardId = creditCards[0] ? creditCards[0].id : null;
    }
  }

  private created() {
    const unsubscribe = this.$store.subscribe(({ type, payload }: MutationPayload) => {
      if (REMOVE_ORDER_MUTATION_TYPE === type) {
        this.close();
      } else if ('socketOrderPaidOnlineFailed' === type && this.createdOrder && payload.id === this.createdOrder.id && this.redirectUrl !== null) {
        this.createdOrder.creditCard = HiddenCreditCardFactory.createFromRawCreditCard(payload.creditCard);
        this.openOrderFailedPopup();
      } else if ('orderHistory/createTakeAway' === type && this.createdOrder && payload.id === this.createdOrder.id && this.redirectUrl !== null) {
        this.createdOrder.creditCard = HiddenCreditCardFactory.createFromRawCreditCard(payload.creditCard);
        this.openOrderSuccessPopup();
      }
    });

    this.editableOrder = cloneDeep(this.order);

    if (null !== this.user) {
      this.editableOrder.client.name = this.user.name;
      this.editableOrder.client.phone = this.user.phone;
    }

    this.selectTime(this.selectedPlace.settings.takeAway.averageLeadTimeTakeAway);

    //Todo: remove this
    this.editableOrder.placeId = this.selectedPlace.id;

    if (!this.selectedPlace.settings.widget.takeAwayEnabled) {
      this.changeOrderTypeToDelivery();
    }

    this.payOnline = this.selectedPlace.isOnlineOrderWithPaymentEnabled;
    this.selectedCreditCardId = this.allCreditCards[0] ? this.allCreditCards[0].id : null;
  }

  private subscribeForOtp(): AbortController {
    const abortController = new AbortController();

    if ('OTPCredential' in window) {
      navigator.credentials.get(<any> {
        otp: { transport: ['sms'] },
        signal: abortController.signal
      }).then((otp: any) => {
        this.code = otp.code;

        const validateCodeButton = <any> this.$refs.validateCodeButton;

        validateCodeButton.click();
      }).catch((err) => {
        console.log(err);
      });
    }

    return abortController;
  }

  private async createOrder() {
    this.$v.$touch();

    if (!this.$v.$invalid && this.user && (!this.payOnline || this.selectedCreditCardId)) {
      try {
        let data: any = {
          ...this.editableOrder,
          creditCard: null,
        };

        delete data.client;

        if (this.payOnline) {
          data.creditCard = {
            id: this.selectedCreditCardId,
          };

          const creditCard = this.newCreditCards.find(
            ({ id }: CreditCardModel): boolean => id === this.selectedCreditCardId,
          );

          if (undefined !== creditCard) {
            data.creditCard.details = {
              number: creditCard.number.value,
              year: creditCard.expirationDate.year,
              month: creditCard.expirationDate.month,
              verificationValue: creditCard.cvc.value,
              cardHolder: creditCard.holderName.value,
              shouldBeSave: creditCard.shouldBeSave,
            };
            data.creditCard.details = creditCard.encryptedData;
          }
        }

        const { order, redirectUrl } = await this.createOrderAction({
          order: data,
          place: this.selectedPlace,
          payOnline: this.payOnline,
        });

        this.createdOrder = order;

        if (!this.payOnline) {
          return this.openOrderSuccessPopup();
        }

        if (redirectUrl) {
          this.redirectUrl = redirectUrl;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  private async sendPhoneConfirmation() {
    const orderValidation = <any> this.$v.editableOrder;

    orderValidation.client.$touch();

    if (!orderValidation.client.$invalid && !this.phoneConfirmationSent) {
      try {
        this.subscribeForOtp();

        const { time, id } = await takeAwayService.sendPhoneConfirmation(this.editableOrder.client);

        this.time = time;
        this.editableOrder.phoneConfirmationId = id;
      } catch (error) {
        if (429 === error.response.status) {
          this.time = error.response.data.time;
          this.editableOrder.phoneConfirmationId = error.response.data.phoneConfirmationId;

          this.errors.phoneConfirmation.push({
            id: error.response.data.id,
            params: error.response.data.params,
          });
        }
      }

      this.phoneConfirmationSent = true;

      this.$nextTick(() => {
        const input = <any> this.$refs.codeInput;

        input.focus();
      });
    }
  }

  private removeCreditCard(creditCardId: string) {
    this.popupController.createPopup(ConfirmRemoveCardPopup, {
      propsData: {
        question: 'Вы точно хотите удалить эту банковскую карту?',
        creditCard: this.allCreditCards.find(({ id }) => id === this.selectedCreditCardId),
      },
      listeners: [
        {
          event: 'confirm',
          callback: async () => {
            const newCardIndex = this.newCreditCards.findIndex((creditCard: CreditCardModel): boolean => creditCard.id === creditCardId)

            if (newCardIndex !== -1) {
              this.newCreditCards.splice(newCardIndex, 1);

              this.selectedCreditCardId = this.allCreditCards[0] ? this.allCreditCards[0].id : null;

              return;
            }

            this.removeCreditCardAction(creditCardId);

            this.selectedCreditCardId = this.allCreditCards[0] ? this.allCreditCards[0].id : null;
          },
        },
      ],
    });
  }

  private async resendPhoneConfirmation() {
    if (this.time <= 0) {
      this.subscribeForOtp();

      const { time } = await takeAwayService.resendPhoneConfirmation({
        phoneConfirmationId: this.editableOrder.phoneConfirmationId,
      });

      this.time = time;
    }
  }

  private async validatePhoneConfirmation() {
    this.$v.code.$touch();

    if (!this.$v.code.$invalid && !this.phoneConfirmationValid) {
      try {
        await this.verifyPhoneConfirmation({
          phoneConfirmation: {
            id: this.editableOrder.phoneConfirmationId,
            code: this.code,
          },
          client: this.editableOrder.client,
        });

        this.phoneConfirmationValid = true;
      } catch (error) {
        console.log(error)
        this.errors.code = {
          id: 'b23a9ddf-b060-4d45-bf0d-749c1131cf69',
        };
      }
    }
  }

  private async openAddCardPopup() {
    this.popupController.createPopup(AddCardPopup, {
      listeners: [
        {
          event: 'add',
          callback: this.addCard,
        }
      ]
    });
  }

  private async openOrderFailedPopup() {
    this.popupController.createPopup(OrderFailedConfirmedPopup, {
      propsData: {
        order: this.createdOrder,
        place: this.selectedPlace,
      },
    });

    this.redirectUrl = null;
  }

  private async openOrderSuccessPopup() {
    this.popupController.createPopup(OrderSuccessConfirmedPopup, {
      propsData: {
        order: this.createdOrder,
        place: this.selectedPlace,
      },
    });

    this.redirectUrl = null;
    this.removeOrder();
  }

  private addCard(card: CreditCardModel) {
    this.newCreditCards.unshift(card);
    this.selectedCreditCardId = card.id;

    const creditCard = document.getElementById('credit-card-container');

    if (creditCard) {
      creditCard.scrollLeft = 0;
    }
  }

  private changeOrderTypeToTakeAway() {
    if (this.selectedPlace.settings.widget.takeAwayEnabled) {
      this.editableOrder.type = this.ORDER_TYPES.TakeAway;
    }
  }

  private changeOrderTypeToDelivery() {
    if (this.selectedPlace.settings.widget.deliveryEnabled) {
      this.editableOrder.type = this.ORDER_TYPES.Delivery;
    }
  }

  private selectTime(datetime: number) {
    this.editableOrder.datetime = datetime;
  }

  private enablePayOffline() {
    if (this.selectedPlace.isOnlineOrderWithoutPaymentEnabled) {
      this.payOnline = false;
    }
  }

  private enablePayOnline() {
    if (this.selectedPlace.isOnlineOrderWithPaymentEnabled) {
      this.payOnline = true;
    }
  }

  private close() {
    this.$destroy();
  }

  @Action('verifyPhoneConfirmation', {
    namespace: 'takeAway/order'
  })
  private verifyPhoneConfirmation!: ActionMethod;

  @Action('createOrder', {
    namespace: 'takeAway/order'
  })
  private createOrderAction!: ActionMethod;

  @Action('removeCreditCard', {
    namespace: 'user'
  })
  private removeCreditCardAction!: ActionMethod;

  @Mutation('removeOrder', {
    namespace: 'takeAway/order'
  })
  private removeOrder!: MutationMethod;
}
