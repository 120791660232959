

























































































































































import BasePageSpinner from '@/components/base/BasePageSpinner.vue';
import CreditCard from '@/components/ui/creditCard/CreditCard.vue';
import OrderSuccessConfirmedPopup from '@/components/ui/popups/takeAway/OrderSuccessConfirmedPopup/index.vue';
import OrderProductList from '@/components/ui/takeAway/OrderProductList/OrderProductList.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import HiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import getPopupController from '@/lib/vuePopup/index';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces/index';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import Place from '@/models/place';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ActionMethod, MutationMethod, MutationPayload } from 'vuex';
import { Action, Mutation } from 'vuex-class';
import {REMOVE_ORDER_MUTATION_TYPE} from "@/store/takeAway/order";

@Component({
  name: 'OrderFailedConfirmedPopup',
  components: {
    BasePageSpinner,
    CreditCard,
    PopupWrapper,
    OrderProductList,
  },
})
export default class OrderFailedConfirmedPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  @Prop({
    type: Object,
    required: true,
  })
  private place!: Place;

  private popupController: PopupControllerPublicProperties = getPopupController();
  private creditCard: HiddenCreditCard | null = null;
  private isDisplayOrderDetails: boolean = false;
  private redirectUrl: string | null = null;

  private created() {
    this.creditCard = this.order.creditCard;

    this.addCreditCard(this.order.creditCard);

    const unsubscribe = this.$store.subscribe(({ type, payload }: MutationPayload) => {
      if (this.redirectUrl === null) {
        return;
      }

      if ('socketOrderPaidOnlineFailed' === type && payload.id === this.order.id) {
        this.openOrderFailedPopup();
      } else if ('orderHistory/createTakeAway' === type && payload.id === this.order.id) {
        this.openOrderSuccessPopup();
      }
    });
  }

  private async retryPayment(): Promise<any> {
    const { redirectUrl, status } = await this.retryPaymentAction(this.order.id);

    if ('SUCCESSFUL' === status.toUpperCase()) {
      return this.openOrderSuccessPopup();
    }

    if ([ 'FAILED', 'EXPIRED', 'ERROR' ].includes(status.toUpperCase())) {
      return this.openOrderFailedPopup();
    }

    if (redirectUrl) {
      this.redirectUrl = redirectUrl;
    }
  }

  private async cancelOrder(): Promise<any> {
    await this.cancelOrderAction(this.order.id);

    this.removeOrder();

    this.close();
  }

  private async openOrderFailedPopup() {
    this.popupController.createPopup(OrderFailedConfirmedPopup, {
      propsData: {
        order: this.order,
        place: this.place,
      },
      listeners: [
        {
          event: 'close',
          callback: this.close,
        },
      ],
    });

    this.redirectUrl = null;
  }

  private async openOrderSuccessPopup() {
    this.popupController.createPopup(OrderSuccessConfirmedPopup, {
      propsData: {
        order: this.order,
        place: this.place,
      },
      listeners: [
        {
          event: 'close',
          callback: this.close,
        },
      ],
    });

    this.redirectUrl = null;
    this.removeOrder();
  }

  private toggleOrderDetailsVisibility() {
    this.isDisplayOrderDetails = !this.isDisplayOrderDetails;
  }

  private close() {
    this.$emit('close');

    this.$destroy();
  }

  @Action('retryPayment', {
    namespace: 'takeAway/order',
  })
  private retryPaymentAction!: ActionMethod;

  @Action('cancelOrder', {
    namespace: 'takeAway/order',
  })
  private cancelOrderAction!: ActionMethod;

  @Action('getOrderByOrderId', {
    namespace: 'orderHistory',
  })
  private getOrderByOrderId!: ActionMethod;

  @Mutation('removeOrder', {
    namespace: 'takeAway/order'
  })
  private removeOrder!: MutationMethod;

  @Mutation('addCreditCard', {
    namespace: 'user'
  })
  private addCreditCard!: MutationMethod;
}
