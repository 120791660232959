

































































import RouteToPlacePopup from '@/components/ui/popups/takeAway/RouteToPlacePopup/index.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import HiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { MutationMethod } from 'vuex';

@Component({
  name: 'OrderSuccessConfirmedPopup',
  components: {
    PopupWrapper
  }
})
export default class OrderSuccessConfirmedPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  @Prop({
    type: Object,
    required: true,
  })
  private place!: any;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private created() {
    this.addCreditCard(this.order.creditCard);
  }

  private redirectToOrderViewPage() {
    this.$router.replace({
      name: 'orderView',
      params: {
        projectId: this.$route.params.projectId,
        orderId: this.order.id,
      }
    });

    this.close();
  }

  private openRouteToPlacePopup() {
    this.popupController.createPopup(RouteToPlacePopup, {
      propsData: {
        place: this.place,
      }
    });
  }

  private close() {
    this.removeOrder();

    this.$emit('close');

    this.$destroy();
  }

  @Mutation('removeOrder', {
    namespace: 'takeAway/order'
  })
  private removeOrder!: MutationMethod;

  @Mutation('addCreditCard', {
    namespace: 'user'
  })
  private addCreditCard!: MutationMethod;
}
